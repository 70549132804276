// 首页轮播下营销活动页面
<template>
  <div class="marketBox">
    <div class="Section">
      <div class="breadcrumbNav">
        <span class="navName">当前位置 : </span>
        <div class="activeNav">
          <span>
            <router-link to="/home"
            ><a href="#">{{ clickVal }} </a></router-link>
          </span>
        </div>
      </div>
      <ul class="filterUl">
        <li
          v-for="(item, index) in filterItems"
          :key="index"
          @click="filterFun(item,index)"
          :class="{ active: Boolean(filterType === index) }"
        >
          {{ item.sort }}
          <i
            :class="
              item.filterMark == 'price' ? Boolean(item.show === 1)?'active el-icon-caret-top  iconTop':'el-icon-caret-top  iconTop':Boolean(filterType === index)?'active el-icon-bottom':'el-icon-bottom'
            "
            aria-hidden="true"
          ></i>
           <i v-if="item.filterMark == 'price'"
            :class="Boolean(item.show === 2)?'active el-icon-caret-bottom iconBottom':'el-icon-caret-bottom iconBottom'"
            aria-hidden="true"
          ></i>
        </li>
        <!-- <li @click="filterFun('sell')"  :class="{'active':item.show===true}">销量 <i class="el-icon-bottom"></i></li>
        <li @click="filterFun('rise')">上架时间 <i class="el-icon-bottom"></i></li>
        <li @click="filterFun('price')">价格 <i class="el-icon-d-caret"></i></li> -->
        <li>
          <el-checkbox v-model="checked" @change="filterFun()"
            >仅显示有货</el-checkbox
          >
        </li>
      </ul>
      <div class="goodsList" v-if="searchProducts">
        <shopItem
          v-for="(item, index) in searchProducts"
          :key="index"
          class="goods"
          :info="item"
        ></shopItem>
      </div>
      <div class="pageBox">
        <el-pagination
          background
          layout="prev, pager, next"
          :hide-on-single-page="false"
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
const shopItem = () => import("components/index/shopItem.vue");
import {
TagProduct
} from "api/home.js";
export default {
  name: "ProductCategory",
  data() {
    return {
      total: 0,
      current: 1, //当前页
      size: 10, //每页数量
      seachFlag: false,
      searchVal: "",
      searchProducts: [], //初始商品数据
      clickVal: "", //  面包屑 初始，type类型值
      checked: false, //筛选checked 选中状态  初始为选中
      filterType:'',
      sort:0,//传给后台的搜索值
      filterItems: [
        // { sort: "销量", show: false, filterMark: "sell" },
        { sort: "上架时间", show: false, filterMark: "rise" },
        { sort: "价格",show: 0, filterMark: "price" },
      ],
      parmesData: {}, //初始搜索值传参
    };
  },
  computed: {},
  created() {
    this.initData();
  },
  methods: {
    handleCurrentChange(val) {
      this.current = val;
      this.parmesData.current = this.current; //当前页
      this.parmesData.size = this.size; //每页数量
       this.getProducts(this.parmesData);
    },
    initData() {
      let tagId = this.$route.query.tagId;
       this.clickVal =this.$route.query.name;
          this.parmesData = {
            tagId:tagId
          };
      this.parmesData.current = 1; //当前页
      this.parmesData.size = 10; //每页数量
       this.getProducts(this.parmesData);
    },
    getProducts(parmesData) {
      // parmesData.tagId
      TagProduct(parmesData).then((res) => {
          const records =res.data.data;
          this.searchProducts = records;
              // console.log(records)
          this.total = Number(res.data.data.length);
        });
    },
    // 点击筛选条件，获取商品数据渲染
    filterFun(item,index) {
      if (item) {

          this.filterType=index
          if(this.filterType===1){
            if(item.show===1){
              item.show = 2;
              this.sort=3
            }else{
              item.show = 1;
              this.sort=index+1
            }
          }else{
            this.filterItems[1].show=0
            this.sort=index+1
          }
          // 搜索条件 sort：  0 （销售 从高到低）| 1（上架时间  从近到远）| 2 （价格 从低到高 ）| 3（价格 从高到低 ）
            this.parmesData.sort = this.sort; //搜索条件
            this.getProducts(this.parmesData);
      }else {
        //显示有货  isHaveStock   0-> 否,1->是 ", paramType = "query", dataType = "integer"
        if (this.checked == false) {
          this.parmesData.isHaveStock = 0; //否
            this.getProducts(this.parmesData);
        } else {
          //显示有货
           this.parmesData.isHaveStock = 1; //是
            this.getProducts(this.parmesData);
        }
      }
    },
   
  },
  watch: {
  },
  components: {
    shopItem,
  },

  mounted() {},
  updated() {},
};
</script>
<style lang="less">
@import "~style/theme.less";
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: @theme;
  color: #fff;
}
/* 上一页，下一页样式 */
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  background: #fff;
  color: #666;
  padding: 2px;
  box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li {
  background: #fff;
  color: #666;
  padding: px;
  box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: @theme;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
.marketBox{
   width: 1200px;
  margin: 0 auto;
}
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}

@import "~style/index.less";
/* 隐藏未编译的变量 */
[v-cloak] {
  display: none;
}
.Section {
  width: 1200px;
  margin: 10px auto 0;
  margin-top: 10px;
  background: #f6f6f6;
  padding-bottom: 40px;
  overflow: hidden;
  .breadcrumbNav {
    padding: 8px 15px 15px 0;
    margin-bottom: 17px;
    font-size: 13px;
    line-height: 20px;
    .navName {
      float: left;
      color: #999;
      padding-right: 8px;
    }
    .activeNav {
      float: left;
      color: #999;
      line-height: 20px;
      span {
        color: @theme;
        padding-right: 8px;
        float: left;
      }
      b {
        float: left;
      }
      .checkUl {
        float: left;
        padding-left: 8px;
        li {
          padding: 0 5px;
          height: 20px;
          line-height: 18px;
          border: 1px solid #F43C38 ;
          color: #F43C38 ;
          font-size: 12px;
          background: #fff;
          margin-right: 8px;
          float: left;
        }
      }
    }
  }


  .filterUl {
    padding: 8px 15px;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    box-sizing: content-box;
    background-color: #ffffff;
    height: 20px;
    line-height: 20px;
    width: 100%;
    float: left;
    li.active {
      color: #F43C38 ;
    }
    li {
      float: left;
      margin-right: 40px;
      color: #666666;
      font-size: 12px;
      position: relative;
        cursor: pointer;
            &:hover{
                color: @theme;
                .el-checkbox{
                  color: @theme;
                }
               
            }
      i {
        font-weight: bolder;
        font-size: 14px;
         color: #666666;
        &.active{
           color: @theme;
        }
        &.iconTop{
            position:absolute;
            right: -18px;
            top: 0;
        }
        &.iconBottom{
            position:absolute;
            right: -18px;
            bottom: 0;
        }
      }
    }
  }
  .goodsList {
    height: auto;
    overflow: hidden;
    float: left;
    min-height: 228px;
    background: #f6f6f6;
    .goods:nth-child(5n + 0) {
      margin-right: 0px;
    }
  }
}
</style>
